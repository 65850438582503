<template>
  <div id="map"></div>
</template>

<script>
import axios from 'axios';
import { loadModules } from 'esri-loader';
import { baseApiUrl } from '../../config';

export default {
  name: 'LandingPageMap',
  data() {
    return {
      portalURL: 'https://aus.gis.arcadis.com/portal',
      appId: 'OqoHGfvoJ7ViIZUt',
      webMapId: 'b0bb061499a7492fab6b0d6e7102406f',
      mapView: {},
      campusRisks: []
    }
  },
  computed: {
    campusList: {
      get() {
        return this.$store.state.climateRiskModule.campusList
      }
    }
  },
  methods: {
    // set to async await to flatten the code
    setup: async function() {
      const { data: campusRisks } = await axios.get(baseApiUrl + 'campus-risks');
      this.campusRisks = campusRisks;
      
      const [
        WebMap,
        MapView,
        esriConfig,
        esriId,
        OAuthInfo,
        Portal,
        PortalItem,
        Graphic,
        GraphicsLayer,
        Point,
        SimpleMarkerSymbol,
        SimpleLineSymbol,
        PopupTemplate,
      ] = await loadModules(
        [
          'esri/WebMap',
          'esri/views/MapView',
          'esri/config',
          'esri/identity/IdentityManager',
          'esri/identity/OAuthInfo',
          'esri/portal/Portal',
          'esri/portal/PortalItem',
          'esri/Graphic',
          'esri/layers/GraphicsLayer',
          'esri/geometry/Point',
          'esri/symbols/SimpleMarkerSymbol',
          'esri/symbols/SimpleLineSymbol',
          'esri/PopupTemplate'
        ],
        { css: true }
      )

      // auth
      esriConfig.portalUrl = this.portalURL
      const authInfo = new OAuthInfo({
        appId: this.appId,
        popup: false
      });
      esriId.registerOAuthInfos([authInfo]);
      const portal = new Portal({
        url: this.portalURL
      });
      const portalItem = new PortalItem({
        id: this.webMapId,
        portal: portal
      });
      portal.authMode = 'immediate';
      await portal.load()
      await esriId.checkSignInStatus(this.portalURL + '/sharing/rest')

      // create webmap
      const webMap = new WebMap({
        portalItem: portalItem
      });

      const mapView = new MapView({
        map: webMap,
        container: 'map',
        center: [146.682256, -33.134083], // Long, Lat
        zoom: 5,
        basemap: 'dark-gray'
      });

      mapView.when(() => {
        const graphicsLayer = new GraphicsLayer()
        graphicsLayer.renderer = {
          type: "simple",
          symbol: {
            type: "simple-marker",
            color: "blue",
            outline: {
                color: "white",
                width: 1
            }
          },
          visualVariables: [
            {
              type: "size",
              field: null, // Not using a specific field
              stops: [
                { size: 9, value: 1155581 },
                { size: 6, value: 9244648 },
                { size: 3, value: 73957190 },
                { size: 1.5, value: 591657527 }
              ]
            }
          ]
        };

        const graphics = []

        const simpleMarkerSymbol = new SimpleMarkerSymbol({
          angle: 0,
          color: '#e4610f',
          outline: new SimpleLineSymbol({
            cap: "round",
            color: '#e4610f',
            join: "round",
            miterLimit: 1,
            style: "solid",
            width: 1
          }),
          path: "undefined",
          size: 5,
          style: "circle",
          xoffset: 0,
          yoffset: 0
        });

        this.campusList.forEach(campus => {
          const point = new Point({
            latitude: campus.latitude,
            longitude: campus.longitude,
          })

          const popupTemplate = new PopupTemplate({
            title: `Campus: ${campus.label}`,
            content: this.getPopupTemplateContent(campus),
          })

          const graphic = new Graphic({
            geometry: point,
            symbol: simpleMarkerSymbol,
            popupTemplate,
            attributes: {
              latitude: campus.latitude,
              longitude: campus.longitude,
            }
          })
          graphics.push(graphic)
        });

        graphicsLayer.addMany(graphics)
        webMap.add(graphicsLayer)
        webMap.basemap = 'dark-gray'
      })
    },
    getPopupTemplateContent (campus) {
      const relatedCampusRisks = this.campusRisks.filter(risk => risk.campus_code === campus.code)

      const obj = {
        st_very_high_risk: 0,
        st_high_risk: 0,
        st_medium_risk: 0,
        st_low_risk: 0,
        st_very_low_risk: 0,
        lt_very_high_risk: 0,
        lt_high_risk: 0,
        lt_medium_risk: 0,
        lt_low_risk: 0,
        lt_very_low_risk: 0,
      }

      relatedCampusRisks.length && relatedCampusRisks.forEach(risk => {
        switch (risk.st_climate_risk_rating_code) {
          case 'very_high':
            obj.st_very_high_risk++;
            break;
          case 'high':
            obj.st_high_risk++;
            break;
          case 'moderate':
            obj.st_medium_risk++;
            break;
          case 'low':
            obj.st_low_risk++;
            break;
          case 'very_low':
            obj.st_very_low_risk++;
            break;
        }

        switch (risk.lt_climate_risk_rating_code) {
          case 'very_high':
            obj.lt_very_high_risk++;
            break;
          case 'high':
            obj.lt_high_risk++;
            break;
          case 'moderate':
            obj.lt_medium_risk++;
            break;
          case 'low':
            obj.lt_low_risk++;
            break;
          case 'very_low':
            obj.lt_very_low_risk++;
            break;
        }
      })

      return `
        <table class="risk-scores-table" style="width: 100%;">
          <tr>
            <th colspan="3"><b>Campus Risk Score</b></th>
          </tr>
          <tr>
            <th><b>Risk Rating</b></th>
            <th><b>Short Term</b></th>
            <th><b>Long Term</b></th>
          </tr>
          <tr>
            <th style="background-color: red; color: white; text-align: left;">Very High</th>
            <td>${obj.st_very_high_risk}</td>
            <td>${obj.lt_very_high_risk}</td>
          </tr>
          <tr>
            <th style="background-color: lightpink; text-align: left;">High</th>
            <td>${obj.st_high_risk}</td>
            <td>${obj.lt_high_risk}</td>
          </tr>
          <tr>
            <th style="background-color: bisque; text-align: left;">Moderate</th>
            <td>${obj.st_medium_risk}</td>
            <td>${obj.lt_medium_risk}</td>
          </tr>
          <tr>
            <th style="background-color: lightgreen; text-align: left;">Low</th>
            <td>${obj.st_low_risk}</td>
            <td>${obj.lt_low_risk}</td>
          </tr>
          <tr>
            <th style="background-color: forestgreen; color: white; text-align: left;">Very Low</th>
            <td>${obj.st_very_low_risk}</td>
            <td>${obj.lt_very_low_risk}</td>
          </tr>
        </table>
        
        `;
    }
  },
  async mounted() {
    await this.setup()
  }
}
</script>

<style scoped>
#map {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  /* height: 550px;
  width: 40vw; */
}
</style>

<style>
.risk-scores-table > tbody > tr > th,
.risk-scores-table > tbody > tr > td  {
  border: 1px solid black;
  border-collapse: collapse;
}

.risk-scores-table > tbody > tr {
  text-align: center;
}
</style>