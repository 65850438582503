<template>
  <div
    :class="`bar-chart ${cssClasses}`"
    :styles="styles"
    v-loading="isDataLoading"
    ref="barChartContainer"
  ></div>
</template>
<script>
/*eslint-disable */

  import { Chart, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, BarController } from 'chart.js'
  
  Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, BarController)

  export default {
    name: 'BarChart',
    props: {
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      chartData:{
        type: Object,
        default: () => {}
      },
      chartOptions:{
        type: Object,
        default: () => {}
      },
      isDataLoading:{
        type: Boolean,
        default: false
      },
      height: {
        type: String,
        default: '300px',
      },
      enableSeparators: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        labelSeparatorPlugin: {
          id: 'separatorLines',
          afterDraw(chart) {
            const { ctx, chartArea: { top, bottom, left, right } } = chart;
            const yLabels = chart.scales.y.ticks;

            ctx.save();
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)'; // Separator line color
            ctx.lineWidth = 1;

            for (let i = 0; i < yLabels.length - 1; i++) {
              const y1 = chart.scales.y.getPixelForTick(i);
              const y2 = chart.scales.y.getPixelForTick(i + 1);
              const midY = (y1 + y2) / 2;
              
              ctx.beginPath();
              ctx.moveTo(left - 600, midY);
              ctx.lineTo(right, midY);
              ctx.stroke();
            }

            ctx.restore();
          }
        },
      }
    },
    watch: {
      chartData() {
        this.createChart()
      },
    },
    methods: {
      createChart() {
        if (document.getElementById(this.chartId)) {
          this.$refs.barChartContainer.removeChild(document.getElementById(this.chartId))
        }
        
        const canvas = document.createElement('canvas');
        canvas.id = this.chartId;
        this.$refs.barChartContainer.appendChild(canvas);

        const ctx = document.getElementById(this.chartId)
        new Chart(ctx, {
          type: 'bar',
          options: this.chartOptions,
          data: {
            datasets: this.chartData.datasets,
            labels: this.chartData.labels,
          },
          plugins: this.enableSeparators ? [this.labelSeparatorPlugin] : []
        });
      }
    },
    mounted() {
      this.createChart()
    },
  }
</script>

<style scoped>
.bar-chart {
  height: v-bind('height') !important;
  max-height: v-bind('height') !important;
}
</style>
