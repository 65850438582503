<template>
  <el-collapse v-model="activeItem">
    <el-collapse-item name="primary-dashboard">
      <template #title>
        <el-text class="mx-1 arc-orange-font" tag="b" size="large"><el-icon><DataAnalysis /></el-icon> HIGH AND VERY HIGH CHILD RISK TABLE</el-text>
      </template>
      <el-row :gutter="10">
        <!-- <el-col :span="8" style="align-self: center;">
          <el-card>
             <el-row justify="space-between">
              <el-col :span="8">
                <el-select
                  v-model="selectedadaptationImplementationPieChartTerm"
                  placeholder="Select Term"
                  size="small"
                  filterable
                >
                  <el-option
                    v-for="item in termSelection"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="From outer to inner circle: Very High, High, Moderate, Low, Very Low."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row> 
            <div class= "small-chart">
              <BarChart
                chartId="adaptationImplementationBarChart"
                :chartData="adaptationImplementationBarData"
                :chartOptions="adaptationCompletionBarChartOptions"
              />
            </div>
          </el-card>
        </el-col> -->
        <el-col :span="24">
          <ChildHighRiskTable
              :type=type
            />
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
/*eslint-disable */
import { QuestionFilled, DataAnalysis } from '@element-plus/icons-vue'
import PieChart from './charts/PieChart.vue';
import ChildHighRiskTable from './ChildHighRiskTable.vue'
import BarChart from './charts/BarChart.vue';

export default {
  name: "PrimaryDashboard",
  components: {
    PieChart,
    QuestionFilled,
    DataAnalysis,
    ChildHighRiskTable,
    BarChart
  },
  props: ["childRiskSummary", "initialCRAList", "type"],
  data() {
    return {
      loadState: false,
      dataLoaded: true,
      termSelection: [
        { label: "Short Term", value: "st" },
        { label: "Long Term", value: "lt" },
      ],
      selectedadaptationImplementationPieChartTerm: "st",
      adaptationCompletionBarChartOptions: {
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
          y: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              display: true,
              font: {
                size: 10,
              },
            }
          },
        },
        plugins: {
          title: {
            text: 'Adaptation Implementation Timing',
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 10,
              font: {
                size: 9
              }
            }
          },
          separatorLines: false
        },
        maintainAspectRatio: false,
      },
    }
  },
  computed: {
    adaptationImplementationBarDataSet() {
      const returnVal = [
        {
          label: 'Complete',
          backgroundColor: '#46D267',
          data: []
        },
        {
          label: 'Incomplete',
          backgroundColor: '#DD4B39',
          data: []
        },
      ];
      
      const priority = {
        very_high_priority: 0,
        high_priority: 0,
        medium_priority: 0,
        low_priority: 0,
        very_low_priority: 0,
      }

      const data = {
        Complete: structuredClone(priority),
        Incomplete: structuredClone(priority),
      }
      
      this.adaptationData?.forEach(adaptation => {
        const currentStat = adaptation.completed ? 'Complete' : 'Incomplete'
        
        data[currentStat][adaptation.adaptation_timing] ++
      });

      Object.keys(data)?.forEach(dataset => {
        const foundObj = returnVal.find(val => val.label == dataset)
        const index = returnVal.indexOf(foundObj);
        returnVal[index].data = Object.values(data[dataset])
      })

      return returnVal;
    },
    adaptationImplementationBarData() {
      return {
        labels: [
          'Very high priority (<1 year)',
          'High priority (1-5 years)',
          'Medium priority (5-10 year)',
          'Low priority (10-20 years)',
          'Very low priority (20+ years)'
        ],
        datasets: this.adaptationImplementationBarDataSet
      }
    },
  },
  methods: {},
}
</script>

<style scoped>
.small-chart {
  max-width: 300px;
  margin:  8px auto;
}

.arc-primary-font{
  color: #0C0808
}
.arc-orange-font{
  color: #E4610F
}

.el-collapse {
  border: 0;
}
</style>