<template>
  <el-table :data="pageSelect" header-cell-class-name="custom-tbl-header" size="small"
    :height="windowHeight * screenFactor" style="width: 100%" v-loading="loadState">
    <el-table-column prop="id" label="ID" width="50" v-loading="loadState">
    </el-table-column>
    <el-table-column prop="parent_risk_statement"
      v-if="type !== 'enterprise'"
      label="Parent"
      width="230"
      v-loading="loadState"
    ></el-table-column>
    <template v-if="type === 'campus'">
      <el-table-column prop="region_label" label="Region" width="70" v-loading="loadState">
      </el-table-column>
      <el-table-column prop="campus_label" label="Campus" width="120" v-loading="loadState">
      </el-table-column>
    </template>
    <el-table-column prop="climate_variables_labels"
      v-if="type !== 'enterprise'"
      label="Climate Hazard"
      width="190"
      v-loading="loadState"
    >
      <template #default="scope">
        <el-tag
          v-for="label in scope.row.climate_variables_labels"
          :key="label"
          class="m-1"
          type="primary"
          effect="plain"
          size="small"
        >
          {{ label }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="sub_asset_type_label"
      v-if="type !== 'enterprise'"
      label="Asset Level 2"
      width="125"
    >
    </el-table-column>
    <el-table-column prop="risk_statement"
      label="Risk Statement" 
      :width="type === 'enterprise' ? 330 : 230"
    ></el-table-column>
    <el-table-column prop="consequence_areas_labels"
      label="TAFE NSW Consequence Area"
      :width="type === 'enterprise' ? 197 : 150"
    >
      <template #default="scope">
        <el-tag
          v-for="label in scope.row.consequence_areas_labels"
          :key="label"
          class="m-1"
          type="primary"
          effect="plain"
          size="small"
        >
          {{ label }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="assumed_controls" label="Assumed Controls" width="200">
    </el-table-column>
    <el-table-column prop="risk_type_label" label="Risk Type" width="100" v-if="type !== 'region'">
    </el-table-column>

    <el-table-column label="Risk assessment" align="center">
      <el-table-column label="Short term" align="center">
        <el-table-column prop="st_climate_risk_likelihood_label" label="Likelihood" width="120">
        </el-table-column>
        <el-table-column prop="st_climate_risk_consequence_label" label="Consequence" width="120">
        </el-table-column>
        <el-table-column prop="st_climate_risk_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.st_climate_risk_rating_code" :label="scope.row.st_climate_risk_rating_label" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Long term" align="center">
        <el-table-column prop="lt_climate_risk_likelihood_label" label="Likelihood" width="120">
        </el-table-column>
        <el-table-column prop="lt_climate_risk_consequence_label" label="Consequence" width="120">
        </el-table-column>
        <el-table-column prop="lt_climate_risk_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.lt_climate_risk_rating_code" :label="scope.row.lt_climate_risk_rating_label" />
          </template>
        </el-table-column>
      </el-table-column>
    </el-table-column>
    <!-- <el-table-column prop="prop_adaptation_action" label="Proposed Adaptation Actions" width="140" fixed="right">
      <template #default="scope">
        <template v-if="scope.row.campus_risk_adaptations.length === 0">
          <el-button class="m-1" type="primary" plain size="small" title="Add New Adaptation Actions"
            @click="addAdaptationAction(scope.row.id)"><i class="fa fa-plus"></i></el-button>
        </template>
        <template v-else>
          <el-space wrap v-for="adaptIdx in Math.ceil(scope.row.campus_risk_adaptations.length / 3)" :key="adaptIdx">
            <div
              v-for="(adaptItem, adaptIndex) in scope.row.campus_risk_adaptations.slice(3 * (adaptIdx - 1), 3 * adaptIdx)"
              :key="adaptIndex">
              <el-button plain class="m-1" :type="adaptItem.is_implemented ? 'success' : 'warning'" size="small"
                title="Adaptation Action Details"
                @click="viewAdaptationAction(scope.row.id, adaptIndex + 3 * (adaptIdx - 1) + 1, adaptItem)">{{
                  adaptIndex + 3 * (adaptIdx - 1) + 1 }}</el-button>
              <template
                v-if="(scope.row.campus_risk_adaptations.length == (adaptIndex + 3 * (adaptIdx - 1) + 1) && scope.row.campus_risk_adaptations.length < 9)">
                <el-button plain class="m-1" type="primary" size="small" title="Add New Adaptation Actions"
                  @click="addAdaptationAction(scope.row.id)"><i class="fa fa-plus"></i></el-button>
              </template>
            </div>
          </el-space>
        </template>
      </template>
    </el-table-column> -->
    <el-table-column prop="prop_adaptation_action" label="Proposed Adaptation Actions" width="140" fixed="right">
      <template #default="scope">
        <el-row>
          <el-col :span="8"
            v-for="(adaptItem, adaptIndex) in scope.row.risk_adaptations"
            :key="adaptIndex"
          >
            <el-button
              class="m-1"
              :type="adaptItem.is_implemented ? 'success' : 'warning'" 
              plain
              size="small"
              @click="viewAdaptationAction(scope.row.id, adaptItem)"
              title="View Adaptation Action Details"
            >
              {{ adaptIndex + 1 }}
            </el-button>
          </el-col>
          <el-col :span="8">
            <el-button
              class="m-1"
              type="primary"
              plain
              size="small"
              @click="addAdaptationAction(scope.row.id)"
              :disabled = "scope.row.risk_adaptations?.length >= 9"
              :title="scope.row.risk_adaptations?.length >= 9 ? 'Maximum # of Adaptation Actions Reached' : 'Add New Adaptation Actions'"
            ><i class="fa fa-plus"></i>
            </el-button>
          </el-col>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="Risk treatment" align="center" fixed="right">
      <template #header>
        <label>
          Risk treatment
          <el-popover
            placement="bottom"
            width="510"
            popper-style="overflow-y: auto"
            trigger="click">
            <template #reference>
              <el-icon><QuestionFilled /></el-icon>
            </template>
            <h6 style="text-align: center;">Corrective vs Preventive measure</h6>
            <el-table id="popover-table" :data="popOverData">
              <el-table-column :width="140" property="title" />
              <el-table-column :width="340" property="content" />
            </el-table>
          </el-popover>
        </label>
      </template>
      <el-table-column label="Short term" align="center">
        <el-table-column prop="st_risk_treatment_likelihood_label" label="Likelihood" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.st_risk_treatment_likelihood_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentLikelihoodList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="st_risk_treatment_consequence_label" label="Consequence" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.st_risk_treatment_consequence_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentConsequencesList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="st_risk_treatment_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.st_risk_treatment_rating_code" :label="scope.row.st_risk_treatment_rating_label"/>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Long term" align="center">
        <el-table-column prop="lt_risk_treatment_likelihood_label" label="Likelihood" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.lt_risk_treatment_likelihood_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentLikelihoodList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="lt_risk_treatment_consequence_label" label="Consequence" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.lt_risk_treatment_consequence_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentConsequencesList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="lt_risk_treatment_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.lt_risk_treatment_rating_code" :label="scope.row.lt_risk_treatment_rating_label"/>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table-column>
    <el-table-column width="115" fixed="right" align="center">
      <template #header>
        Actions
      </template>
      <template #default="scope">
        <el-tooltip content="Edit Details" placement="bottom" effect="light">
          <el-button type="primary" size="small" @click="editItem(scope.row)" circle>
            <i class="fa fa-edit large"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip content="Save Details" placement="bottom" v-if="scope.row.isEdit" effect="light">
          <el-button type="success" size="small" @click="saveItem(scope.row)" circle>
            <i class="fa fa-save large"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip content="Delete" placement="bottom" effect="light">
          <el-button v-if="userIsAdmin" type="danger" size="small" @click="deleteItem(scope.row)" circle>
            <i class="fa fa-trash large"></i>
          </el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <el-row>
    <el-col :span="12">
      <el-pagination background small :page-size="parseInt(perPage ? perPage : 1)" layout="total, prev, pager, next"
        :total="craList.length" :default-current-page="1" @current-change="selectedPage" style="margin-top:1rem" />
    </el-col>
    <el-col :span="12" align="right">
      <el-tooltip content="Download Adaptation" placement="bottom" effect="light">
        <el-button @click="handleOnclickDownload" type="primary" class="mt-2" size="large" circle>
          <i class="fa-solid fa-download"></i>
        </el-button>
      </el-tooltip>
    </el-col>
  </el-row>

  <el-dialog v-model="dialogAddAdaptationAction" title="" draggable>
    <template #header>
      <h4>Adaptation Action</h4>
    </template>
    <el-form :model="actionForm" label-width="auto" label-position="left" size="small">
      <template v-if="type === 'campus'">
        <el-form-item label="Region">
          <el-select v-model="actionForm.regionCode" placeholder="Select" filterable clearable class="dialog-select"
            disabled>
            <el-option v-for="item in regionsList" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
        <el-form-item label="Campus">
          <el-select v-model="actionForm.campusCode" placeholder="Select" filterable clearable class="dialog-select"
            disabled>
            <el-option v-for="item in campusList" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
      </template>
      <template v-if="type !== 'enterprise'">
        <el-form-item label="Climate Hazard">
          <el-select v-model="actionForm.climateHazardCodes" placeholder="Select" size="small" class="dialog-select"
            disabled multiple collapse-tags collapse-tags-tooltip>
            <el-option v-for="item in climateHazardsList" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
        <el-form-item label="Asset Level 2">
          <el-select v-model="actionForm.assetLevel2Code" placeholder="Select" size="small" class="dialog-select"
            disabled>
            <el-option v-for="item in subAssetTypesList" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="Measure">
        <el-select v-model="actionForm.measureCode" placeholder="Select" filterable class="dialog-select">
          <el-option v-for="item in adaptationMeasuresList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="Measure description">
        <el-input v-model="actionForm.measureDesc" type="textarea" />
      </el-form-item>
      <el-form-item label="Adaptation Category">
        <el-select v-model="actionForm.adaptationCategoryCode" placeholder="Select" class="dialog-select">
          <el-option v-for="item in adaptationCategoriesList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="Adaptation Type">
        <el-select v-model="actionForm.adaptationTypeCode" placeholder="Select" class="dialog-select">
          <el-option v-for="item in adaptationTypesList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="Owner Responsible">
        <el-select v-model="actionForm.ownerResponsible" placeholder="Select" class="dialog-select">
          <el-option
            v-for="item in ownersList"
            :key="item.code"
            :label="item.label"
            :value="item.label"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Additional Owners">
        <el-input v-model="actionForm.additionalOwners" type="textarea" />
      </el-form-item>
      <el-form-item label="Implementation Timing">
        <el-select v-model="actionForm.implementationTiming" placeholder="Select" class="dialog-select">
          <el-option v-for="item in adaptationTimingsList" :key="item.code" :label="item.label"
            :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="Feasibility">
        <el-input-number v-model="actionForm.feasibility" :min="0" :max="5" size="small" controls-position="right" />
      </el-form-item>
      <el-form-item label="Indicative cost">
        <el-input-number v-model="actionForm.indicativeCost" :min="0" :max="5" size="small" controls-position="right" />
      </el-form-item>
      <el-form-item label="Impact">
        <el-input-number v-model="actionForm.impact" :min="0" :max="5" size="small" controls-position="right" />
      </el-form-item>
      <el-form-item label="Mean">
        <el-tag class="ml-2" type="success">{{ actionForm.mean }}</el-tag>
      </el-form-item>
      <el-form-item label="Has the action been implemented?">
        <el-switch v-model="actionForm.isImplemented" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogAddAdaptationAction = false" color="#E4610F" plain>Cancel</el-button>
        <el-button @click="onSubmit" color="#E4610F">
          {{actionForm.adaptationActionId ? "Update" : "Save"}}
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
/*eslint-disable */
import { ElMessage, ElMessageBox } from "element-plus";
import { baseApiUrl } from "../../config.js";
import { ElNotification } from 'element-plus'
import axios from 'axios';
import {
  Check,
  Delete,
  Edit,
  QuestionFilled
} from '@element-plus/icons-vue'
import { climateRiskMixin } from "../mixins/climateRiskMixin.js";
import RiskRating from "./RiskRating.vue";
export default {
  name: 'AdaptationTable',
  components: {
    Check,
    Delete,
    Edit,
    RiskRating,
    QuestionFilled
  },
  emits: ["fetchCRA"],
  props: ["initialCRAList", "initialCampusLabel", "initialLoadstate", "type"],
  mixins: [climateRiskMixin],
  data() {
    return {
      popOverData: [
        {
          title: 'Corrective measures',
          content: 'Actions taken after an incident or problem has occurred and reduce the consequence of the risk'
        },
        {
          title: 'Preventive measures',
          content: 'Actions taken before an incident or issue arises to reduce the likelihood of risks occurring'
        }
      ],
      actionForm: {
        regionCode: "",
        campusCode: "",
        adaptationActionId: null,
        craID: null,
        climateHazardCodes: [],
        assetLevel2Code: "",
        measureCode: "",
        measureDesc: "",
        feasibility: 0,
        indicativeCost: 0,
        impact: 0,
        mean: 0,
        adaptationCategoryCode: "",
        adaptationTypeCode: "",
        isImplemented: 0,
        // craAdaptationActionId: null,
        // adaptActionTypeCode: "",
        // action: "",
        ownerResponsible: "",
        additionalOwners: "",
        implementationTiming: "",
        timeline: "",
        adaptationControl: "",
      },
      adaptationControlSet: [
        'Adaptation',
        'Control',
      ],
      // campusLabel: null,
      craList: [],
      currentPage: 1,
      dialogAddAdaptationAction: false,
      loadState: false,
      perPage: 20,
      screenFactor: 0.55,
      selectedCampus: null,
      timelineSet: [
        '0 -1 year',
        '2 - 5 years',
        '5+ years',
      ],
      windowHeight: 0,
    }
  },
  computed: {
    userIsAdmin: {
      get() {
        return this.$store.state.mainModule.userIsAdmin;
      }
    },
    adaptationCategoriesList: {
      get() {
        return this.$store.state.climateRiskModule.adaptationCategoriesList
      }
    },
    // adaptationCurrentMeasureIDs: {
    //   get() {
    //     let currentCRAID = this.actionForm.craID
    //     let currentSubAsset = this.craList.filter(obj => obj.id == currentCRAID)[0]?.subAssetTypeCode
    //     let currentHazard = this.craList.filter(obj => obj.id == currentCRAID)[0]?.climateHazardCode
    //     if (this.type === "region") {
    //       return this.coreAdaptationActionsList
    //         .filter(obj => obj.climateHazardCode == currentHazard)
    //         .map(a => a.adaptation_measure_id)
    //     } else {
    //       return this.coreAdaptationActionsList
    //         .filter(obj => obj.subAssetTypeCode == currentSubAsset && obj.climateHazardCode == currentHazard)
    //         .map(a => a.adaptation_measure_id)
    //     }
    //   }
    // },
    adaptationMeasuresList: {
      get() {
        const current = this
        // let tempAdaptation = []
        // if (current.type === 'region') {
        //   tempAdaptation = this.$store.state.climateRiskModule.regionAdaptationMeasuresList
        // } else {
        //   tempAdaptation = this.$store.state.climateRiskModule.campusAdaptationMeasuresList
        // }
        // if (current.adaptationCurrentMeasureIDs.length > 0) {
        //   return tempAdaptation.filter(item => current.adaptationCurrentMeasureIDs.includes(item.id));
        // }
        // else {
        //   return tempAdaptation
        // }
        return this.$store.state.climateRiskModule.campusAdaptationMeasuresList
      }
    },
    adaptationTimingsList: {
      get() {
        return this.$store.state.climateRiskModule.adaptationTimingsList
      }
    },
    adaptationTypesList: {
      get() {
        return this.$store.state.climateRiskModule.adaptationTypesList
      }
    },
    assessmentConsequencesList: {
      get() {
        return this.$store.state.climateRiskModule.assessmentConsequencesList
      }
    },
    assessmentLikelihoodList: {
      get() {
        return this.$store.state.climateRiskModule.assessmentLikelihoodList
      }
    },
    // campusList: {
    //     get() {
    //         return this.$store.state.climateRiskModule.campusList
    //     }
    // },
    climateHazardsList: {
      get() {
        return this.$store.state.climateRiskModule.climateHazardsList
      }
    },
    // coreAdaptationActionsList: {
    //   get() {
    //     if (this.type === "region") {
    //       return this.$store.state.climateRiskModule.regionCoreAdaptationActionsList
    //     }
    //     return this.$store.state.climateRiskModule.campusCoreAdaptationActionsList
    //   }
    // },
    currentCampusCode: {
      get() {
        return this.$store.state.climateRiskModule.currentCampus ? this.$store.state.climateRiskModule.currentCampus : "albury"
      }
    },
    currentRegionCode: {
      get() {
        return this.$store.state.climateRiskModule.currentRegion || "north"
      }
    },
    usersList: {
      get() {
        return this.$store.state.climateRiskModule.usersList
      }
    },
    ownersList: {
      get() {
        return this.$store.state.climateRiskModule.ownerResponsibleList
      }
    },
    pageSelect: function () {
      let pageList = [];
      let newFilteredList = [].concat(this.craList);
      // let newFilteredList = structuredClone(this.craList);
      let itemsPerPage = this.perPage ? this.perPage : 1
      for (let i = 0; i < this.craList.length / itemsPerPage; i++) {
        pageList.push(newFilteredList.splice(0, itemsPerPage));
      }
      return pageList[this.currentPage - 1];
    },
    subAssetTypesList: {
      get() {
        return this.$store.state.climateRiskModule.subAssetTypesList
      }
    },
  },
  watch: {
    'craList': {
      handler(newValue, oldValue) {
        newValue.forEach((item) => {
          // climateRiskMixin
          this.computeRiskRatingList(item, "risk treatment", "st")
          this.computeRiskRatingList(item, "risk treatment", "lt")
        })
      },
      deep: true
    },
    // 'actionForm.measureCode': {
    //   handler(newValue, oldValue) {
    //     this.setActionFormDetails(newValue)
    //   }
    // },
    'actionForm': {
      handler(newValue, oldValue) {
        let item = newValue
        item.mean = ((item.feasibility + item.indicativeCost + item.impact) / 3).toFixed(1)
      },
      deep: true
    },
    initialCRAList: {
      handler(newValue) {
        this.craList = newValue;
      }
    },
    initialLoadstate: {
      handler(newValue) {
        this.loadState = newValue;
      }
    },
  },
  methods: {
    addAdaptationAction: function (craID) {
      const current = this
      current.resetActionForm()
      current.actionForm.craID = craID
      const itemFound = current.craList.find(obj => obj.id == craID)
      current.actionForm.regionCode = itemFound.region_label
      current.actionForm.campusCode = itemFound.campus_label
      current.actionForm.climateHazardCodes = itemFound.climate_variables_labels
      current.actionForm.assetLevel2Code = itemFound.sub_asset_type_label
      // current.actionForm.adaptActionTypeCode = aaType
      current.dialogAddAdaptationAction = true
    },
    editItem: function (item) {
      const current = this
      current.actionForm.craID = item.id
      current.craList.filter(obj => obj.id == item.id)[0].isEdit = !current.craList.filter(obj => obj.id == item.id)[0].isEdit
      if (!current.craList.filter(obj => obj.id == item.id)[0].isEdit) {
        current.setUpCRAList()
      }
    },
    deleteItem: function (item) {
      const current = this
      let data = {
        id: item.id,
        type: current.type,
      }
      ElMessageBox.confirm(
        "Are you sure you want to delete this entry?",
        "",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "error",
          confirmButtonClass: "error",
        }
      )
        .then(() => {
          this.$store.dispatch('climateRiskModule/DELETE_CRA_ENTRY', data).then(() => {
            current.setUpCRAList()
            ElNotification({
              title: 'Risk Entry',
              message: 'Deleted Successfully',
              type: 'success',
            })

          })
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Deletion canceled",
          });
        });
    },
    handleOnclickDownload: function () {
      let reqUrl = "";
      let filename = "";
      function capitalizeFirstLetter(word) {
        // e.g.: from campus to Campus
        const firstLetter = word.charAt(0)
        const firstLetterCap = firstLetter.toUpperCase()
        const remainingLetters = word.slice(1)
        const capitalizedWord = firstLetterCap + remainingLetters
        return capitalizedWord
      }
      filename = capitalizeFirstLetter(this.type) + "AdaptationsReport.xlsx"
      reqUrl = `${this.type}-risks/adaptations/download`
      const url = baseApiUrl + reqUrl;
      console.log("url", url)
      axios.get(url, { responseType: 'blob' }).then(result => {
        const type = result.headers["content-type"];
        const link = document.createElement("a");
        link.style = "display: none"

        const blob = new Blob(
          [result.data],
          { type }
        )
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.download = filename
        link.click()
        window.URL.revokeObjectURL(url)
      })
    },
    onSubmit: function () {
      const current = this
      let data = {
        ...current.actionForm,
        type: current.type,
      }
      if (data.adaptationActionId) {
        this.$store.dispatch('climateRiskModule/UPDATE_ADAPTATION_ACTION_ENTRY', data).then(() => {
          ElNotification({
            title: 'Adaptation Action Entry',
            message: 'Updated Successfully',
            type: 'success',
          })
          current.setUpCRAList()
          current.dialogAddAdaptationAction = false
        })
      } else {
        this.$store.dispatch('climateRiskModule/SAVE_ADAPTATION_ACTION_ENTRY', data).then(() => {
          ElNotification({
            title: 'Adaptation Action Entry',
            message: 'Saved Successfully',
            type: 'success',
          })
          current.setUpCRAList()
          current.dialogAddAdaptationAction = false
        })
      }

    },
    resetActionForm: function () {
      const current = this
      current.actionForm.craID = null
      current.actionForm.adaptationActionId = null
      current.actionForm.climateHazardCodes = []
      current.actionForm.assetLevel2Code = ""
      current.actionForm.measureCode = ""
      current.actionForm.measureDesc = ""
      current.actionForm.feasibility = 0
      current.actionForm.indicativeCost = 0
      current.actionForm.impact = 0
      current.actionForm.mean = 0
      current.actionForm.timeline = ""
      current.actionForm.adaptationControl = ""
      current.actionForm.adaptationCategoryCode = ""
      current.actionForm.adaptationTypeCode = ""
      current.actionForm.ownerResponsible = ""
      current.actionForm.additionalOwners = ""
      current.actionForm.implementationTiming = ""
      current.actionForm.isImplemented = 0
    },
    saveItem: function (item) {
      const current = this
      current.craList.filter(obj => obj.id == item.id)[0].isEdit = !current.craList.filter(obj => obj.id == item.id)[0].isEdit
      current.loadState = true

      let data = {
        id: item.id,
        subAssetTypeCode: item.sub_asset_type_code,
        climateHazardCode: item.climate_variables.join(", "),
        riskTypeCode: item.risk_type_code,
        consequenceCategoryCode: item.consequence_areas.join(", "),
        risk_statement: item.risk_statement,
        assumed_controls: item.assumed_controls,
        stLikelihoodCode: item.st_climate_risk_likelihood_code,
        stConsequenceCode: item.st_climate_risk_consequence_code,
        stRiskRatingCode: item.st_climate_risk_rating_code,
        ltLikelihoodCode: item.lt_climate_risk_likelihood_code,
        ltConsequenceCode: item.lt_climate_risk_consequence_code,
        ltRiskRatingCode: item.lt_climate_risk_rating_code,
        region: item.region_code,
        campus: item.campus_code,
        type: current.type,

        // adaptations
        stRTConsequence: item.st_risk_treatment_consequence_code,
        stRTLikelihood: item.st_risk_treatment_likelihood_code,
        stRTRating: item.st_risk_treatment_rating_code,
        ltRTConsequence: item.lt_risk_treatment_consequence_code,
        ltRTLikelihood: item.lt_risk_treatment_likelihood_code,
        ltRTRating: item.lt_risk_treatment_rating_code,

        type: current.type,
      }

      this.$store.dispatch('climateRiskModule/UPDATE_CRA_ENTRY', data)
        .then(() => {
          current.setUpCRAList()
          ElNotification({
            title: 'Adaptation Risk Treatment Entry',
            message: 'Saved Successfully',
            type: 'success',
          })
          current.loadState = false
        })
    },
    selectedPage: function (pageNumber) {
      const current = this;
      current.currentPage = pageNumber;
    },
    // setActionFormDetails: function (item) {
    //   const current = this
    //   current.actionForm.mean = ((current.actionForm.feasibility + current.actionForm.indicativeCost + current.actionForm.impact) / 3).toFixed(1)

    //   // let currentCRAID = current.actionForm.craID
    //   let currentSubAsset = current.actionForm.assetLevel2Code
    //   let currentHazard = current.actionForm.climateHazardCode
    //   let currentItem = []
    //   console.log("setActionFormDetails item", item)
    //   console.log("setActionFormDetails current.actionForm", item)
    //   // if (this.type === "region") {
    //   //   currentItem = this.coreAdaptationActionsList.filter(obj =>
    //   //     obj.climateHazardCode == currentHazard
    //   //     && obj.adaptationMeasureCode == item
    //   //   )[0]
    //   // } else {
    //   //   currentItem = this.coreAdaptationActionsList.filter(obj =>
    //   //     obj.subAssetTypeCode == currentSubAsset
    //   //     && obj.climateHazardCode == currentHazard
    //   //     && obj.adaptationMeasureCode == item
    //   //   )[0]
    //   // }

    //   if (current.actionForm.measureCode && currentItem) {
    //     current.actionForm.measureDesc = currentItem.measure_description
    //     current.actionForm.feasibility = currentItem.feasibility
    //     current.actionForm.indicativeCost = currentItem.indicative_cost
    //     current.actionForm.impact = currentItem.impact
    //     current.actionForm.mean = currentItem.mean
    //     current.actionForm.adaptationCategoryCode = currentItem.adaptationCategoryCode
    //     current.actionForm.adaptationTypeCode = currentItem.adaptationTypeCode
    //     current.actionForm.timeline = currentItem.timeline
    //     current.actionForm.adaptationControl = currentItem.adaptation_control
    //   }
    // },
    setUpCRAList: function () {
      const current = this
      current.loadState = true
      let code = current.currentCampusCode
      if (current.type === "region") {
        code = current.currentRegionCode
      }
      current.$emit("fetchCRA", code)
    },
    viewAdaptationAction: function (craID, aaItem) {
      const current = this
      current.resetActionForm()
      const ownerResponsible = this.ownersList.find(owner => owner.label === aaItem.owner_responsible);

      current.actionForm.craID = craID
      current.actionForm.adaptationActionId = aaItem.id
      const itemFound = current.craList.find(obj => obj.id == craID)
      current.actionForm.regionCode = itemFound.region_label
      current.actionForm.campusCode = itemFound.campus_label
      current.actionForm.climateHazardCodes = itemFound.climate_variables_labels
      current.actionForm.assetLevel2Code = itemFound.sub_asset_type_label
      current.actionForm.measureCode = aaItem.adaptation_measure_code
      current.actionForm.measureDesc = aaItem.measure_description
      current.actionForm.feasibility = aaItem.feasibility
      current.actionForm.indicativeCost = aaItem.indicative_cost
      current.actionForm.impact = aaItem.impact
      current.actionForm.mean = aaItem.mean
      // current.actionForm.timeline = aaItem.timeline
      // current.actionForm.adaptationControl = aaItem.adaptation_control
      current.actionForm.adaptationCategoryCode = aaItem.adaptation_category_code
      current.actionForm.adaptationTypeCode = aaItem.adaptation_type_code
      current.actionForm.ownerResponsible = ownerResponsible?.label
      current.actionForm.additionalOwners = aaItem.additional_owners
      current.actionForm.implementationTiming = aaItem.adaptation_timing_label
      current.actionForm.isImplemented = aaItem.is_implemented == 1 ? true : false
      current.dialogAddAdaptationAction = true
    },
  },
  mounted() {
    const current = this;
    current.windowHeight = window.innerHeight
    window.onresize = () => {
      current.windowHeight = window.innerHeight
    }
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}

.arc-orange-font {
  color: #E4610F
}

.arc-light-orange-font {
  color: #EB894B
}

.arc-primary-font {
  color: #0C0808
}

.custom-icon-space {
  flex: none;
  margin-right: 10px;
}

.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
}
</style>
<style>
.custom-tbl-header {
  color: #E4610F !important;
  /* background: #FFF !important; */
}

.el-pagination.is-background .btn-next.is-active,
.el-pagination.is-background .btn-prev.is-active,
.el-pagination.is-background .el-pager li.is-active {
  background-color: #E4610F !important;

}

.el-pager li:hover {
  color: #E4610F
}

#popover-table > .el-table__inner-wrapper > .el-table__header-wrapper > .el-table__header {
  display: none;
}
</style>