<template>
  <el-scrollbar max-height="95dvh">
    <div class="common-layout">
      <el-container>
        <el-header>
          <el-row class="mt-2">
            <el-col>
              <el-card shadow="always">
                <el-row>
                  <el-col :span="1" class="custom-icon-space">
                    <el-avatar :size="30" class="arc-light-orange-bg">
                      <el-icon><Suitcase /></el-icon>
                    </el-avatar>
                  </el-col>
                  <el-col :span="23">
                    <el-breadcrumb separator="/" class="mt-2">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Portfolio Assessment</el-breadcrumb-item>
                    </el-breadcrumb>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-header>
        <el-main class="mt-2">
          <el-tabs type="border-card" tab-position="bottom" model-value="climateHazardTab">
            <el-tab-pane name="climateHazardTab">
              <template #label>
                <span class="arc-orange-font">
                  <i class="fa-solid fa-earth-americas"></i>
                  <el-text tag="b" class="arc-primary-font"> Climate Hazard Explorer</el-text>
                </span>
              </template>
              <iframe src="https://aus.gis.arcadis.com/portal/apps/dashboards/ab3f18b773a546d4ac29bacaacc1f5e3"
                class="custom-iframe-gis"></iframe>
            </el-tab-pane>
            <el-tab-pane name="riskModelDashboardTab">
              <template #label>
                <span class="arc-orange-font">
                  <i class="fa-solid fa-square-poll-vertical"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk Model Dashboard</el-text>
                </span>
              </template>
              <iframe :src="dashboardURL" class="custom-iframe-powerbi"></iframe>
            </el-tab-pane>
            <el-tab-pane name="riskAndAdaptationRegisterTab">
              <template #label>
                <span class="arc-orange-font">
                  <i class="fa-solid fa-table"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk and Adaptation Register</el-text>
                </span>
              </template>
              <el-card shadow="never" class="mb-3">
                <Dashboard
                  :initialCRAList="initialCRAList"
                  :childRiskSummary="childRiskSummary"
                  type="portfolio"
                  :adaptationData="adaptationRatingSummary"
                />
              </el-card>
              <el-card shadow="never" class="mb-3">
                <PrimaryDashboard
                  :initialCRAList="initialCRAList"
                  :childRiskSummary="childRiskSummary"
                  type="portfolio"
                />
              </el-card>
              <el-card shadow="never" class="mb-3">
                <AssessmentFilter
                  @changeFilter="handleOnChangeFilter"
                  :initialAdvanceFilters="advanceFilters"
                  type="portfolio"
                />
              </el-card>
              <el-tabs v-model="activeRegisterTab" tab-position="left">
                <el-tab-pane label="RISK" name="riskTab">
                  <RiskTable
                    @fetchCRA="handleOnFetchCRA"
                    :filters="advanceFilters"
                    :initialCRAList="initialCRAList"
                    :initialLoadstate="initialLoadstate"
                    type="portfolio"
                  />
                </el-tab-pane>
                <el-tab-pane label="ADAPTATION" name="adaptationTab">
                  <AdaptationTable
                    @fetchCRA="handleOnFetchCRA"
                    :filters="advanceFilters"
                    :initialCRAList="initialCRAList"
                    initialCampusLabel=""
                    :initialLoadstate="initialLoadstate"
                    type="portfolio"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </div>
  </el-scrollbar>
</template>

<script>
/*eslint-disable */
import AdaptationTable from './AdaptationTable.vue'
import AssessmentFilter from './AssessmentFilter.vue'
import Dashboard from './Dashboard.vue'
import PrimaryDashboard from './PrimaryDashboard.vue'
import RiskTable from './RiskTable.vue'
import axios from 'axios';
import { baseApiUrl } from "../../config.js";
import { ref } from 'vue'
import { ElNotification } from 'element-plus'
import { Suitcase } from '@element-plus/icons-vue'

export default {
  name: 'Portfolio',
  components: {
    AdaptationTable,
    AssessmentFilter,
    Dashboard,
    PrimaryDashboard,
    RiskTable,
    Suitcase,
  },
  data() {
    return {
      activeRegisterTab: ref('riskTab'),
      advanceFilters: {
        selectedAssetLevel2: "all",
        selectedClimateHazard: "all",
        selectedConsequence: "all",
        selectedRiskType: "all",
        selectedSTConsequence: "all",
        selectedSTLikelihood: "all",
        selectedSTRating: [],
        selectedLTConsequence: "all",
        selectedLTLikelihood: "all",
        selectedLTRating: [],
      },
      initialLoadstate: false,
      rawCRAList: [],
      childRiskSummary: [],
      adaptationRatingSummary: [],
    }
  },
  computed: {
    currentAssessment: {
      get() {
        return this.$store.state.mainModule.currentAssessment
      }
    },
    dashboardURL: {
      get() {
        return this.$store.state.mainModule.assessmentMapScenes.filter(obj => obj.code == this.$store.state.mainModule.currentAssessment)[0].climateDashboardURL
      }
    },
    initialCRAList: {
      get() {
        let filteredList = this.rawCRAList.map(rawCra => {
          delete Object.assign(rawCra, {['risk_adaptations']: rawCra['portfolio_risk_adaptations'] })['portfolio_risk_adaptations'];
          return rawCra
        })

        if (this.advanceFilters.selectedClimateHazard !== "all") {
          // Note: recursive error occurs when filteredList is not in structuredClone
          filteredList = structuredClone(filteredList.filter((cra) => 
            cra.climate_variables.includes(this.advanceFilters.selectedClimateHazard)
          ))
        }
        if (this.advanceFilters.selectedAssetLevel2 !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedAssetLevel2 === cra.sub_asset_type_code
          )
        }
        if (this.advanceFilters.selectedConsequence !== "all") {
          // Note: recursive error occurs when filteredList is not in structuredClone
          filteredList = structuredClone(filteredList.filter((cra) =>
            cra.consequence_areas.includes(this.advanceFilters.selectedConsequence)
          ))
        }
        if (this.advanceFilters.selectedRiskType !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedRiskType === cra.risk_type_code
          )
        }
        // Short term
        if (this.advanceFilters.selectedSTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTConsequence === cra.st_climate_risk_consequence_code
          )
        }
        if (this.advanceFilters.selectedSTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTLikelihood === cra.st_climate_risk_likelihood_code
          )
        }
        if (this.advanceFilters.selectedSTRating.length && this.advanceFilters.selectedSTRating !== 'all') {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTRating.some(rating => rating === cra.st_climate_risk_rating_code)
          )
        }
        // Long term
        if (this.advanceFilters.selectedLTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTConsequence === cra.lt_climate_risk_consequence_code
          )
        }
        if (this.advanceFilters.selectedLTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTLikelihood === cra.lt_climate_risk_likelihood_code
          )
        }
        if (this.advanceFilters.selectedLTRating.length && this.advanceFilters.selectedLTRating !== 'all') {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTRating.some(rating => rating === cra.lt_climate_risk_rating_code)
          )
        }

        return filteredList
      }
    },
  },
  methods: {
    handleOnFetchCRA: function () {
      const current = this
      current.initialLoadstate = true

      const url = baseApiUrl + 'portfolio-risks'
      const childRiskSummary = []

      axios.get(url).then(({ data }) => {
        if (data) {
          const adaptationRatingSummary = [];
          current.rawCRAList = data.map(risk => {
            // consolidating child risk summary
            const tempChildRiskSummary = {
              risk_statement: risk.risk_statement,
              risk_ratings: {
                very_low: risk.lt_child_risk_summary.very_low + risk.st_child_risk_summary.very_low,
                low: risk.lt_child_risk_summary.low + risk.st_child_risk_summary.low,
                moderate: risk.lt_child_risk_summary.moderate + risk.st_child_risk_summary.moderate,
                high: risk.lt_child_risk_summary.high + risk.st_child_risk_summary.high,
                very_high: risk.lt_child_risk_summary.very_high + risk.st_child_risk_summary.very_high,
                no_rating: risk.lt_child_risk_summary.no_rating + risk.st_child_risk_summary.no_rating,
              },
            }

            childRiskSummary.push(tempChildRiskSummary)

            // renaming fields to be consistent
            risk.parent_risk_statement = risk.enterprise_risk_statement
            risk.consequence_areas = risk.portfolio_risks_consequence_areas?.map(consequence => consequence.code) || []
            risk.consequence_areas_labels = risk.portfolio_risks_consequence_areas?.map(consequence => consequence.label) || []
            risk.climate_variables = risk.portfolio_risks_climate_variables?.map(climateVar => climateVar.code) || []
            risk.climate_variables_labels = risk.portfolio_risks_climate_variables?.map(climateVar => climateVar.label) || []

            risk.portfolio_risk_adaptations.forEach(adaptation => {
              adaptationRatingSummary.push({
                adaptation_timing: adaptation.adaptation_timing_code,
                completed: adaptation.is_implemented,
                label: adaptation.adaptation_timing_label
              });
            });

            return risk
          });

          current.childRiskSummary = childRiskSummary
          current.adaptationRatingSummary = adaptationRatingSummary
        }
      }).catch(() => {
        ElNotification({
          type: 'error',
          message: 'Failed to get data...',
          title: 'ERROR'
        })
      }).finally(() => {
        current.initialLoadstate = false
      })
    },
    handleOnChangeFilter: function ({ field, value }) {
      this.advanceFilters[field] = value
    }
  },
  beforeMount() {
    const current = this
    let currentAssessmentItem = current.$route.path.replace("/", "")
    this.$store.commit('mainModule/setCurrentAssessment', currentAssessmentItem)
    this.handleOnFetchCRA()
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}

.arc-orange-font {
  color: #E4610F
}

.arc-light-orange-font {
  color: #EB894B
}

.arc-primary-font {
  color: #0C0808
}

.custom-icon-space {
  flex: none;
  margin-right: 10px;
}

.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
}
</style>
<style>
.el-tabs--border-card>.el-tabs__header {
  background-color: #F0F0F0 !important
}

.el-tabs__item:hover {
  color: #E4610F !important;
}

.el-tabs__item.is-active {
  color: #E4610F !important;
}

.el-tabs__active-bar {
  background-color: #E4610F !important;
}

.custom-iframe-gis {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
}
</style>