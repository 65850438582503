<template>
  <!-- <el-text class="" tag="b" size="">HIGH AND VERY HIGH CHILD RISK TABLE</el-text> -->
  <el-table
    :data="pageSelect"
    :height="windowHeight * screenFactor - 21"
    header-cell-class-name="custom-tbl-header"
    size="small"
    style="width: 100%"
    v-loading="loadState"
    :row-class-name="tableRowClassName"
    cell-class-name="text-break"
  >
    <el-table-column prop="id" label="ID" width="50" v-loading="loadState" fixed="left"></el-table-column>

    <el-table-column prop="parent_risk_statement"
      label="Parent"
      width="230"
      fixed="left"
      v-loading="loadState"
    ></el-table-column>

    <el-table-column prop="region_label"
      v-if="type === 'portfolio'"
      label="Region"
      width="70"
      fixed="left"
      v-loading="loadState"
    >
    </el-table-column>
    <el-table-column prop="campus_label"
      v-if="type === 'portfolio'"
      label="Campus"
      width="120"
      fixed="left"
      v-loading="loadState"
    >
    </el-table-column>

    <el-table-column prop="risk_statement"
      label="Risk Statement"
      :width="type === 'enterprise' ? 330 : 230"
      fixed="left"
    ></el-table-column>

    <el-table-column prop="climate_variables_labels"
      label="Climate Hazard"
      width="190"
      fixed="left"
      v-loading="loadState"
    >
      <template #default="scope">
        
          <el-tag
            class="m-1"
            type="primary"
            effect="plain"
            size="small"
            v-for="label in scope.row.climate_variables_labels"
            :key="label"
          >
            {{ label }}
          </el-tag>
      </template>
    </el-table-column>

    <el-table-column prop="sub_asset_type_label"
      label="Asset Level 2"
      width="125"
      fixed="left"
    ></el-table-column>

    <el-table-column prop="consequence_areas_labels"
      label="TAFE NSW Consequence Area"
      :width="type === 'enterprise' ? 197 : 150"
    >
      <template #default="scope">
          <el-tag
            v-for="label in scope.row.consequence_areas_labels"
            :key="label"
            class="m-1"
            type="primary"
            effect="plain"
            size="small"
          >
            {{ label }}
          </el-tag>
      </template>
    </el-table-column>

    <el-table-column prop="assumed_controls" label="Assumed Controls" width="200">
    </el-table-column>
    <el-table-column prop="risk_type_label" label="Risk Type" width="100">
    </el-table-column>
    <el-table-column label="Risk assessment" align="center">
      <el-table-column label="Short term" align="center">
        <el-table-column prop="st_climate_risk_likelihood_label" label="Likelihood" width="120">
        </el-table-column>
        <el-table-column prop="st_climate_risk_consequence_label" label="Consequence" width="120">
        </el-table-column>
        <el-table-column prop="st_climate_risk_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.st_climate_risk_rating_code" :label="scope.row.st_climate_risk_rating_label"/>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Long term" align="center">
        <el-table-column prop="lt_climate_risk_likelihood_label" label="Likelihood" width="120">
        </el-table-column>
        <el-table-column prop="lt_climate_risk_consequence_label" label="Consequence" width="120"></el-table-column>
        <el-table-column prop="lt_climate_risk_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.lt_climate_risk_rating_code" :label="scope.row.lt_climate_risk_rating_label"/>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table-column>

  </el-table>
  <el-row>
    <el-col :span="12">
      <el-pagination
        background small
        :page-size="parseInt(perPage ? perPage : 1)"
        layout="total, prev, pager, next"
        :total="rawChildRiskList.length" :default-current-page="1"
        @current-change="selectedPage"
        style="margin-top:1rem"
      />
    </el-col>
  </el-row>

  
</template>

<script>
/*eslint-disable */
import axios from "axios";
import { baseApiUrl } from "../../config.js";
import RiskRating from "./RiskRating.vue";

export default {
  name: 'RiskTable',
  components: {
    RiskRating
  },
  props: ["type"],
  data() {
    return {
      windowHeight: 0,
      screenFactor: 0.55,
      loadState: false,
      rawChildRiskList: [],
      perPage: 3,
      currentPage: 1,
    }
  },
  computed: {
    subAssetTypesList: {
      get() {
        return this.$store.state.climateRiskModule.subAssetTypesList
      }
    },
    consequenceCategoriesList: {
      get() {
        return this.$store.state.climateRiskModule.consequenceCategoriesList
      }
    },
    riskTypesList: {
      get() {
        return this.$store.state.climateRiskModule.riskTypesList
      }
    },
    assessmentLikelihoodList: {
      get() {
        return this.$store.state.climateRiskModule.assessmentLikelihoodList
      }
    },
    assessmentConsequencesList: {
      get() {
        return this.$store.state.climateRiskModule.assessmentConsequencesList
      }
    },
    pageSelect: function () {
      let pageList = [];
      let newFilteredList = [].concat(this.rawChildRiskList);
      let itemsPerPage = this.perPage ? this.perPage : 1
      for (let i = 0; i < this.rawChildRiskList.length / itemsPerPage; i++) {
        pageList.push(newFilteredList.splice(0, itemsPerPage));
      }
      return pageList[this.currentPage - 1];
    },
  },
  watch: {
  },
  methods: {
    selectedPage: function (pageNumber) {
      const current = this;
      current.currentPage = pageNumber;
    },
    handleOnFetchChildRisk: function() {
      const current = this
      if(current.type == 'enterprise'){
        current.initialLoadstate = true
        const url = baseApiUrl + 'portfolio-risks/high'
  
        axios.get(url).then(({ data }) => {
          if (data) {
            current.rawChildRiskList = data.map(risk => {
              risk.parent_risk_statement = risk.enterprise_risk_statement
              risk.consequence_areas = risk.portfolio_risks_consequence_areas?.map(consequence => consequence.code) || []
              risk.consequence_areas_labels = risk.portfolio_risks_consequence_areas?.map(consequence => consequence.label) || []
              risk.climate_variables = risk.portfolio_risks_climate_variables?.map(climateVar => climateVar.code) || []
              risk.climate_variables_labels = risk.portfolio_risks_climate_variables?.map(climateVar => climateVar.label) || []
  
              return risk
            });
          }
        }).catch(() => {
          ElNotification({
            type: 'error',
            message: 'Failed to get data',
            title: 'ERROR'
          })
        }).finally(() => {
          current.initialLoadstate = false
        })

      }
      else if(current.type == 'portfolio'){
        current.initialLoadstate = true
        const url = baseApiUrl + 'campus-risks/high'
  
        axios.get(url).then(({ data }) => {
          if (data) {
            current.rawChildRiskList = data.map(risk => {
              risk.parent_risk_statement = risk.portfolio_risk_statement
              risk.consequence_areas = risk.campus_risks_consequence_areas?.map(consequence => consequence.code) || []
              risk.consequence_areas_labels = risk.campus_risks_consequence_areas?.map(consequence => consequence.label) || []
              risk.climate_variables = risk.campus_risks_climate_variables?.map(climateVar => climateVar.code) || []
              risk.climate_variables_labels = risk.campus_risks_climate_variables?.map(climateVar => climateVar.label) || []
  
              return risk
            });
          }
        }).catch(() => {
          ElNotification({
            type: 'error',
            message: 'Failed to get data',
            title: 'ERROR'
          })
        }).finally(() => {
          current.initialLoadstate = false
        })

      }
    },
  },
  mounted() {
    
  },
  beforeMount(){
    this.handleOnFetchChildRisk()
  }
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}

.arc-orange-font {
  color: #E4610F
}

.arc-light-orange-font {
  color: #EB894B
}

.arc-primary-font {
  color: #0C0808
}

.custom-icon-space {
  flex: none;
  margin-right: 10px;
}

.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
}
</style>
<style>
.custom-tbl-header {
  color: #E4610F !important;
  /* background: #FFF !important; */
}

.el-pagination.is-background .btn-next.is-active,
.el-pagination.is-background .btn-prev.is-active,
.el-pagination.is-background .el-pager li.is-active {
  background-color: #E4610F !important;
}

.el-pager li:hover {
  color: #E4610F
}

.dialog-select {
  width: 100%;
}

.el-select-dropdown {
  width: 500px;
}

.el-select-dropdown__item {
  overflow: visible;
}

.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
  --el-table-tr-bg-color: rgba(238, 238, 238, 0.4);
}

.childRiskSummaryModal {
  width: 30%;
}

.el-table .cell {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
</style>