export const ActionTypes = {
  GET_ADAPTATION_TIMINGS: "GET_ADAPTATION_TIMINGS",
  GET_ADAPTATION_TYPES: "GET_ADAPTATION_TYPES",
  GET_ADAPTATION_CATEGORIES: "GET_ADAPTATION_CATEGORIES",
  GET_ADAPTATION_COBENEFIT: "GET_ADAPTATION_COBENEFIT",
  GET_ADAPTATION_MEASURES: "GET_ADAPTATION_MEASURES",
  GET_ASSESSMENT_CONSEQUENCES: "GET_ASSESSMENT_CONSEQUENCES",
  GET_ASSESSMENT_LIKELIHOOD: "GET_ASSESSMENT_LIKELIHOOD",
  GET_ASSESSMENT_RATING: "GET_ASSESSMENT_RATING",
  // GET_CAMPUS_CORE_ADAPTATION_ACTIONS: "GET_CAMPUS_CORE_ADAPTATION_ACTIONS",
  GET_CAMPUSES: "GET_CAMPUSES",
  GET_CLIMATE_HAZARDS: "GET_CLIMATE_HAZARDS",
  GET_CONSEQUENCE_CATEGORIES: "GET_CONSEQUENCE_CATEGORIES",
  GET_ENTERPRISES: "GET_ENTERPRISES",
  GET_PORTFOLIO: "GET_PORTFOLIO",
  // GET_REGION_ADAPTATION_MEASURES: "GET_REGION_ADAPTATION_MEASURES",
  // GET_REGION_CORE_ADAPTATION_ACTIONS: "GET_REGION_CORE_ADAPTATION_ACTIONS",
  GET_REGIONS: "GET_REGIONS",
  GET_RISK_TYPES: "GET_RISK_TYPES",
  GET_SUB_ASSET_TYPES: "GET_SUB_ASSET_TYPES",
  DELETE_CRA_ENTRY: "DELETE_CRA_ENTRY",
  SAVE_CRA_ENTRY: "SAVE_CRA_ENTRY",
  UPDATE_CRA_ENTRY: "UPDATE_CRA_ENTRY",
  SAVE_ADAPTATION_ACTION_ENTRY: "SAVE_ADAPTATION_ACTION_ENTRY",
  UPDATE_ADAPTATION_ACTION_ENTRY: "UPDATE_ADAPTATION_ACTION_ENTRY",
  GET_USERS: "GET_USERS",
  GET_OWNER_RESPONSIBILITIES: "GET_OWNER_RESPONSIBILITIES"
};

export const MutationTypes = {
  CLEAR_ADAPTATION_TIMINGS: "CLEAR_ADAPTATION_TIMINGS",
  CLEAR_ADAPTATION_TYPES: "CLEAR_ADAPTATION_TYPES",
  CLEAR_ADAPTATION_CATEGORIES: "CLEAR_ADAPTATION_CATEGORIES",
  CLEAR_ADAPTATION_COBENEFIT: "CLEAR_ADAPTATION_COBENEFIT",
  CLEAR_ADAPTATION_MEASURES: "CLEAR_ADAPTATION_MEASURES",
  CLEAR_ASSESSMENT_CONSEQUENCES: "CLEAR_ASSESSMENT_CONSEQUENCES",
  CLEAR_ASSESSMENT_LIKELIHOOD: "CLEAR_ASSESSMENT_LIKELIHOOD",
  CLEAR_ASSESSMENT_RATING: "CLEAR_ASSESSMENT_RATING",
  // CLEAR_CAMPUS_CORE_ADAPTATION_ACTIONS: "CLEAR_CAMPUS_CORE_ADAPTATION_ACTIONS",
  CLEAR_CAMPUSES: "CLEAR_CAMPUSES",
  CLEAR_CLIMATE_HAZARDS: "CLEAR_CLIMATE_HAZARDS",
  CLEAR_CONSEQUENCE_CATEGORIES: "CLEAR_CONSEQUENCE_CATEGORIES",
  CLEAR_CURRENT_CAMPUS: "CLEAR_CURRENT_CAMPUS",
  CLEAR_PORTFOLIO_LIST: "CLEAR_PORTFOLIO_LIST",
  // CLEAR_REGION_ADAPTATION_MEASURES: "CLEAR_REGION_ADAPTATION_MEASURES",
  // CLEAR_REGION_CORE_ADAPTATION_ACTIONS: "CLEAR_REGION_CORE_ADAPTATION_ACTIONS",
  CLEAR_REGIONS: "CLEAR_REGIONS",
  CLEAR_RISK_TYPES: "CLEAR_RISK_TYPES",
  CLEAR_SUB_ASSET_TYPES: "CLEAR_SUB_ASSET_TYPES",
  CLEAR_USERS: "CLEAR_USERS",

  SET_ADAPTATION_TIMINGS: "SET_ADAPTATION_TIMINGS",
  SET_ADAPTATION_TYPES: "SET_ADAPTATION_TYPES",
  SET_ADAPTATION_CATEGORIES: "SET_ADAPTATION_CATEGORIES",
  SET_ADAPTATION_COBENEFIT: "SET_ADAPTATION_COBENEFIT",
  SET_ADAPTATION_MEASURES: "SET_ADAPTATION_MEASURES",
  SET_ASSESSMENT_CONSEQUENCES: "SET_ASSESSMENT_CONSEQUENCES",
  SET_ASSESSMENT_LIKELIHOOD: "SET_ASSESSMENT_LIKELIHOOD",
  SET_ASSESSMENT_RATING: "SET_ASSESSMENT_RATING",
  // SET_CAMPUS_CORE_ADAPTATION_ACTIONS: "SET_CAMPUS_CORE_ADAPTATION_ACTIONS",
  SET_CAMPUSES: "SET_CAMPUSES",
  SET_CLIMATE_HAZARDS: "SET_CLIMATE_HAZARDS",
  SET_CONSEQUENCE_CATEGORIES: "SET_CONSEQUENCE_CATEGORIES",
  // SET_CURRENT_ASSESSMENT: "SET_CURRENT_ASSESSMENT",
  SET_CURRENT_CAMPUS: "SET_CURRENT_CAMPUS",
  SET_ENTERPRISE_LIST: "SET_ENTERPRISE_LIST",
  SET_PORTFOLIO_LIST: "SET_PORTFOLIO_LIST",
  // SET_REGION_ADAPTATION_MEASURES: "SET_REGION_ADAPTATION_MEASURES",
  // SET_REGION_CORE_ADAPTATION_ACTIONS: "SET_REGION_CORE_ADAPTATION_ACTIONS",
  SET_REGIONS: "SET_REGIONS",
  SET_SUB_ASSET_TYPES: "SET_SUB_ASSET_TYPES",
  SET_RISK_TYPES: "SET_RISK_TYPES",
  SET_USERS: "SET_USERS",
  SET_OWNER_RESPONSIBILITIES: "SET_OWNER_RESPONSIBILITIES"
};
