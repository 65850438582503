/* eslint-disable */
import axios from "axios";
import { ActionTypes, MutationTypes } from "./types";
import { baseApiUrl } from "../../../../config.js";

export default {
  async [ActionTypes.GET_ADAPTATION_TIMINGS]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "/general/adaptation-timings";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ADAPTATION_TIMINGS);
          result.data.forEach((item) => {
            commit("SET_ADAPTATION_TIMINGS", item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ADAPTATION_TYPES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "/general/adaptation-types";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ADAPTATION_TYPES);
          result.data.forEach((item) => {
            commit("SET_ADAPTATION_TYPES", item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ADAPTATION_CATEGORIES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "/general/adaptation-categories";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ADAPTATION_CATEGORIES);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_ADAPTATION_CATEGORIES, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ADAPTATION_COBENEFIT]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "adaptationCobenefit";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ADAPTATION_COBENEFIT);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_ADAPTATION_COBENEFIT, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ADAPTATION_MEASURES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/adaptation-measures";
        // const data = {
        //   params: { levelCode: "campus" },
        // };
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ADAPTATION_MEASURES);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_ADAPTATION_MEASURES, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ASSESSMENT_CONSEQUENCES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/climate-risk-consequences";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ASSESSMENT_CONSEQUENCES);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_ASSESSMENT_CONSEQUENCES, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ASSESSMENT_LIKELIHOOD]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/climate-risk-likelihoods";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ASSESSMENT_LIKELIHOOD);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_ASSESSMENT_LIKELIHOOD, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ASSESSMENT_RATING]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/climate-risk-ratings";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_ASSESSMENT_RATING);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_ASSESSMENT_RATING, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  // async [ActionTypes.GET_CAMPUS_CORE_ADAPTATION_ACTIONS]({ commit }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "getCoreAdaptationActions";
  //       const data = {
  //         params: { levelCode: "campus" },
  //       };
  //       axios.get(url, data).then((result) => {
  //         commit(MutationTypes.CLEAR_CAMPUS_CORE_ADAPTATION_ACTIONS);
  //         result.data.forEach((item) => {
  //           commit(MutationTypes.SET_CAMPUS_CORE_ADAPTATION_ACTIONS, item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  async [ActionTypes.GET_CAMPUSES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/campuses";
        axios.get(url).then((result) => {
          //   commit("clearCampuses");
          commit(MutationTypes.CLEAR_CURRENT_CAMPUS);
          result.data.forEach((item) => {
            // commit("setCampuses", item);
            commit(MutationTypes.SET_CAMPUSES, item);
          });
          //   commit("setCurrentCampus", result.data[0].code);
          commit(MutationTypes.SET_CURRENT_CAMPUS, result.data[0].code);
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_CLIMATE_HAZARDS]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/climate-variables";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_CLIMATE_HAZARDS);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_CLIMATE_HAZARDS, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_CONSEQUENCE_CATEGORIES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/consequence-areas";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_CONSEQUENCE_CATEGORIES);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_CONSEQUENCE_CATEGORIES, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_ENTERPRISES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "enterprise-risks";
        axios.get(url).then((result) => {
          commit(MutationTypes.SET_ENTERPRISE_LIST, result.data);
          // commit("setEnterpriseList", result.data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_PORTFOLIO]({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "portfolio-risks";
        let data = {
          enterprise_risk_id: payload.id,
        };
        let config = {
          responseType: "json",
        };
        axios.get(url, { params: data }, config).then((result) => {
          const newArr = result.data.map((item) => ({
            id: item.id,
            risk_statement: item.risk_statement,
          }));
          commit(MutationTypes.SET_PORTFOLIO_LIST, newArr);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  // async [ActionTypes.GET_REGION_ADAPTATION_MEASURES]({ commit }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/adaptation-measures";
  //       const data = {
  //         params: { levelCode: "region" },
  //       };
  //       axios.get(url, data).then((result) => {
  //         commit(MutationTypes.CLEAR_REGION_ADAPTATION_MEASURES);
  //         result.data.forEach((item) => {
  //           commit(MutationTypes.SET_REGION_ADAPTATION_MEASURES, item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // async [ActionTypes.GET_REGION_CORE_ADAPTATION_ACTIONS]({ commit }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "getCoreAdaptationActions";
  //       const data = {
  //         params: { levelCode: "region" },
  //       };
  //       axios.get(url, data).then((result) => {
  //         commit(MutationTypes.CLEAR_REGION_CORE_ADAPTATION_ACTIONS);
  //         result.data.forEach((item) => {
  //           commit(MutationTypes.SET_REGION_CORE_ADAPTATION_ACTIONS, item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  async [ActionTypes.GET_REGIONS]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/regions";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_REGIONS);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_REGIONS, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_RISK_TYPES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/risk-types";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_RISK_TYPES);
          result.data.forEach((item) => {
            commit(MutationTypes.SET_RISK_TYPES, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.GET_SUB_ASSET_TYPES]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "general/sub-asset-types";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_SUB_ASSET_TYPES);
          // commit("clearSubAssetTypes");
          result.data.forEach((item) => {
            // commit("setSubAssetTypes", item);
            commit(MutationTypes.SET_SUB_ASSET_TYPES, item);
          });
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async [ActionTypes.DELETE_CRA_ENTRY]({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      let url = baseApiUrl + "campus-risks";
      if (payload.type === 'portfolio') {
        url = baseApiUrl + 'portfolio-risks'
      } else if (payload.type === 'enterprise') {
        url = baseApiUrl + 'enterprise-risks'
      }
      let data = {
        id: payload.id,
      };
      let config = {
        responseType: "json",
      };
      axios.delete(url, { params: data }, config).then((result) => {
        resolve();
      });
    });
  },
  async [ActionTypes.SAVE_CRA_ENTRY]({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      let url = baseApiUrl + "campus-risks";
      if (payload.type === 'portfolio') {
        url = baseApiUrl + 'portfolio-risks'
      } else if (payload.type === 'enterprise') {
        url = baseApiUrl + 'enterprise-risks'
      }
      let data = {
        statement: payload.riskStatement,
        consequenceAreas: payload.consequenceCategoryCodes.join(", "),
        riskType: payload.riskTypeCode,
        stConsequence: payload.riskAssessmentConsequenceSTCode,
        stLikelihood: payload.riskAssessmentLikelihoodSTCode,
        stRating: payload.riskAssessmentRatingSTCode,
        ltConsequence: payload.riskAssessmentConsequenceLTCode,
        ltLikelihood: payload.riskAssessmentLikelihoodLTCode,
        ltRating: payload.riskAssessmentRatingLTCode,
        region: payload.regionCode,
        campus: payload.campusCode,
        subAssetType: payload.subAssetTypeCode,
        climateVariables: payload.climateHazardCodes?.join(", "),
        assumedControls: payload.assumedControls,
        portfolioRiskID: payload.portfolioCode?.id,
        enterpriseRiskID: payload.enterpriseCode?.id,

        ntVulnerability: 0,
        ntRiskScore: 0,
        ltVulnerability: 0,
        ltRiskScore: 0,
        impact: 0,
        riskScoreGreaterThan7_5: "riskScoreGreaterThan7_5",
      };
      let config = {
        responseType: "json",
      };
      axios.post(url, data, config).then((result) => {
        resolve();
      });
    });
  },
  async [ActionTypes.UPDATE_CRA_ENTRY]({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      let url = baseApiUrl + "campus-risks";
      if (payload.type === 'portfolio') {
        url = baseApiUrl + 'portfolio-risks'
      } else if (payload.type === 'enterprise') {
        url = baseApiUrl + 'enterprise-risks'
      }
      let data = {
        id: payload.id,
        statement: payload.risk_statement,
        consequenceAreas: payload.consequenceCategoryCode,
        riskType: payload.riskTypeCode,
        stConsequence: payload.stConsequenceCode,
        stLikelihood: payload.stLikelihoodCode,
        stRating: payload.stRiskRatingCode,
        ltConsequence: payload.ltConsequenceCode,
        ltLikelihood: payload.ltLikelihoodCode,
        ltRating: payload.ltRiskRatingCode,
        subAssetType: payload.subAssetTypeCode,
        climateVariables: payload.climateHazardCode,
        assumedControls: payload.assumed_controls,
        region: payload.region,
        campus: payload.campus,

        // adaptations
        stRTConsequence: payload.stRTConsequence,
        stRTLikelihood: payload.stRTLikelihood,
        stRTRating: payload.stRTRating,
        ltRTConsequence: payload.ltRTConsequence,
        ltRTLikelihood: payload.ltRTLikelihood,
        ltRTRating: payload.ltRTRating,
      };
      let config = {
        responseType: "json",
      };
      axios.put(url, data, config).then((result) => {
        resolve();
      });
    });
  },
  async [ActionTypes.SAVE_ADAPTATION_ACTION_ENTRY]({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      let url = baseApiUrl + payload.type + "-risks/adaptations";
     
      let data = {
        adaptationCategory: payload.adaptationCategoryCode,
        adaptationMeasure: payload.measureCode,
        adaptationTiming: payload.implementationTiming,
        adaptationType: payload.adaptationTypeCode,
        // campusRiskId: payload.craID,
        measureDescription: payload.measureDesc,
        user: payload.ownerResponsible,
        additionalOwners: payload.additionalOwners,
        feasibility: payload.feasibility,
        indicativeCost: payload.indicativeCost,
        impact: payload.impact,
        mean: payload.mean,
        isImplemented: payload.isImplemented
      };
      data[`${payload.type}RiskId`] = payload.craID;

      let config = {
        responseType: "json",
      };
      axios.post(url, data, config).then((result) => {
        resolve();
      });
    });
  },
  async [ActionTypes.UPDATE_ADAPTATION_ACTION_ENTRY]({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      // let url = baseApiUrl + "campus-risks/adaptations";
      let url = baseApiUrl + payload.type + "-risks/adaptations";

      let data = {
        id: payload.adaptationActionId,
        adaptationCategory: payload.adaptationCategoryCode,
        adaptationMeasure: payload.measureCode,
        adaptationTiming: payload.implementationTiming,
        adaptationType: payload.adaptationTypeCode,
        // campusRiskId: payload.craID,
        measureDescription: payload.measureDesc,
        user: payload.ownerResponsible,
        additionalOwners: payload.additionalOwners,
        feasibility: payload.feasibility,
        indicativeCost: payload.indicativeCost,
        impact: payload.impact,
        mean: payload.mean,
        isImplemented: payload.isImplemented
      };
      data[`${payload.type}RiskId`] = payload.craID;

      let config = {
        responseType: "json",
      };
      axios.put(url, data, config).then((result) => {
        resolve();
      });
    });
  },
  async [ActionTypes.GET_USERS]({ commit }) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "/general/users";
        axios.get(url).then((result) => {
          commit(MutationTypes.CLEAR_USERS);
          commit("SET_USERS", result.data)
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  async [ActionTypes.GET_OWNER_RESPONSIBILITIES] ({commit}) {
    return new Promise(function (resolve, reject) {
      try {
        let url = baseApiUrl + "/general/owner-responsibles";
        axios.get(url).then((result) => {
          commit(MutationTypes.SET_OWNER_RESPONSIBILITIES, result.data);
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
};
